"use strict";

$(
    function() {
        try {
            var topH = $('._ > .top').height();
            app.fixTop = topH || 0;
        } catch (e) {}
        app.assignNumberControl();
        app.setSelectedLink();
        app.addRegisterCheckBoxes();
        app.attachSvgEvents();
        app.makeSocialButtons();
        app.setBackUrls();
        app.addCoverSpan();
        app.saveProductListInfo();
        app.topMenu.init();
        if (!app.isMobile()) {
            $(window).scroll(
                function () {
                    app.floatingHeader();
                }
            );
            app.floatingHeader();
        }
        app.assignProductOver();
        app.initializeScrollButton();
        app.initACBL();
        app.hamburger.init();
        app.extraMenu && app.extraMenu.init({
            side: 'right',
            selector: '.extramenu-hamb',
            saveState: false
        });
        app.template.setHandlers();
    }
);

var app = {
    _VERSION: 2,
    _vars: {
        v: '1.2',
        tmp: 24,
        putAttributesToProdItemsDirectly: true,
        _customFonts: {
            Linearicons: true
        }
    },
    responsiveMode: true,
    indexLinkPos : 0,
    messages: {},
    productAvailableQuantityUrl: null,
    optionsWereInitialized: false,
    template: {
        setHandlers: function () {
            $('.my-cart-line li:first').on('click', function(e) {
                e.stopPropagation();
                $(this).toggleClass('opened');
            });
            $('.search-form').on('click', function(e) {
                e.stopPropagation();
            });
            $(document).on('click', function () {
                $('.my-cart-line li:first').removeClass('opened');
            });
        },
        initSlider: function (params) {
            var {
                sliderHeight,
                sliderAutoplayTimeout,
                leftMenu,
                minItemCount,
                carouselAutoplayTimeout
            } = params || {};

            var itemCount = leftMenu !== 'Y' ? 5 : 4;
            var generalResponsive = {
                0: {
                    items: 2
                },
                544: {
                    items: 3
                },
                761: {
                    items: 4
                },
                992: {
                    items: leftMenu !== 'Y' ? 5 : 4
                },
                1200: {
                    items: leftMenu !== 'Y' ? 6 : 5
                }
            };
            app.owlSlider.makeBanner({
                speed: sliderAutoplayTimeout,
                setHeight: sliderHeight
            });
            app.owlSlider.makeProductListSlider({
                autoplay: true,
                autoplayTimeout: sliderAutoplayTimeout,
                autoplayHoverPause: true,
                dots: true,
                minItemCount: itemCount,
                oneRow: true,
                speed: sliderAutoplayTimeout,
                1200: itemCount
            });
            app.owlSlider.makeCategoryCarousel({
                autoplayTimeout: sliderAutoplayTimeout,
                dots: true,
                loop: false,
                minItemCount: minItemCount || 4,
                oneRow: true,
                1200: minItemCount || 4
            });
            app.owlSlider.makeCarousel({
                autoHeight: true,
                autoplay: true,
                speed: carouselAutoplayTimeout,
                slideBy: 1,
                smartSpeed: 500,
                lazyLoad: true,
                responsive: generalResponsive
            });
        }
    }
};
